import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import AboutNavigation from "../../components/about/aboutNavigation";
import HeroImage from "../../components/global/heroImage";
import Image from "../../components/global/image"
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import "./licensed-products.scss"

const LicensedProductsPage = () => {

    const data = useStaticQuery(graphql`
    {
      craft {
        page: entry(section: "licensedProducts") {
          ... on Craft_licensedProducts_licensedProducts_Entry {
            id
            title
            pageTitle
            uri
            aboutUsBodyFull
            licensees {
              ... on Craft_licensees_licensee_BlockType {
                licenseeLogo {
                  ... on Craft_websiteGeneral_Asset {
                    url
                    ... on Craft_websiteGeneral_Asset {
					    altText
                    }
                  }
                }
                licenseeName
                licenseeBlurb
              }
            }
            aboutUsHeroImage {
              ... on Craft_aboutUsHeroImage_image_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteGeneral_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
          }
        }
      }
    }
  `)

    const getProducts = (licensees) => {
        if (licensees.length > 0) {
            return (
                licensees.map(licensee => (
                    <li>
                        <Image src={licensee.licenseeLogo[0].url} alt={licensee.licenseeLogo[0].altText} />
                        <h5>{licensee.licenseeName}</h5>
                        <p>{parse(licensee.licenseeBlurb)}</p>
                    </li>
                ))
            )
        } else {
            return null
        }
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd licensed-products-page">

                <PageTitle title={data.craft.page.pageTitle} />
                <HeroImage image={data.craft.page.aboutUsHeroImage}/>

                <div className="content-page">
                    <div className="container-small">
                        
                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <AboutNavigation active={data.craft.page.uri} />
                            </div>
                            <div className="content-right">
                                <h3>{data.craft.page.pageTitle}</h3>

                                {parse(data.craft.page.aboutUsBodyFull)}

                                <div className="licensed-logo">
                                    <ul>
                                        {getProducts(data.craft.page.licensees)}
                                    </ul>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default LicensedProductsPage
